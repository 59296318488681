import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import filter from 'lodash/filter';
import Step1 from './Stepper/Step1';
import Step2 from './Stepper/Step2';
import Step3 from './Stepper/Step3';
import Stepper from 'src/components/Items/Stepper';
import InfoStepper from 'src/components/Items/InfoStepper';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';
import {
  dineroAdd,
  dineroDivide,
  dineroGetPercentage,
  dineroGetSum,
  dineroMultiply,
  dineroSubtractAmount,
  fromISOtoFormat,
  rounder,
} from 'src/common/utils/dataFormat';
import { scrollToTop } from 'src/common/utils/others';
import { OrderState } from 'src/store/reducers/order/types';
import { IClientFetch, IOrder } from 'src/models/IOrder';
import { ISeasonPriceFetch } from 'src/models/ISeasonPrice';
import Logo from 'src/components/Items/Logo';
import './styles.scss';
import './media.scss';

const steps = [
  { label: 'Van & Dates', step: 0 },
  { label: 'Your info', step: 1 },
  { label: 'Reserve van', step: 2 },
];

export interface IPropsOrder {
  order: IOrder;
  onChange: (name: string, value: any) => void;
  setStep: (step: number) => void;
}
export type Order = Pick<IPropsOrder, 'order' | 'onChange'>;

const OrderPage: FC = () => {
  const { initOrder, changeOrder, getExtras, getSeasonPrice, getSubPrices } = useActions();
  const {
    order,
    extra,
    subPrice: { subPriceList },
    van: {
      filters: { start_date, end_date, location, end_location, sub_location },
    },
    oneway: { onewayList },
    seasonPrice: { seasonPriceList },
  } = useTypedSelector((state) => state);
  const homeLocation = useTypedSelector((state) => state.order.van.location);
  const navigate = useNavigate();
  const subId = process.env.REACT_APP_NODE_ENV ? 18 : 27;

  const [step, setStep] = useState(0);
  const days = DateTime.fromISO(end_date!)
    .diff(DateTime.fromISO(start_date!), ['days'])
    .toObject().days;

  const [season, setSeason] = useState<ISeasonPriceFetch | null>(null);
  useEffect(() => {
    seasonPriceList &&
      seasonPriceList.map(seasonPrice =>
        seasonPrice.location === location &&
        fromISOtoFormat(start_date!, 'yyyy/LL/dd') >=
        fromISOtoFormat(seasonPrice.start_date, 'yyyy/LL/dd') &&
        fromISOtoFormat(start_date!, 'yyyy/LL/dd') <=
        fromISOtoFormat(seasonPrice.end_date, 'yyyy/LL/dd') &&
        setSeason(seasonPrice)
      );
  }, [seasonPriceList]);

  useEffect(() => {
    if (extra.extraList.length) {
      const defaultExtra = extra.extraList.filter(
        (ex) =>
          ex.isDefault ||
          (rounder(days!) <= 1 && ex.id === 5) ||
          (rounder(days!) >= 7 && ex.id === 7) ||
          (season && ex.id === 6) ||
          (end_location && ex.id === 10) ||
          (sub_location && !end_location && ex.id === subId)
        // || 
        // (location !== homeLocation && !end_location && ex.id === 17) // near van
      );
      changeOrder('extras', defaultExtra);
    }
  }, [extra.extraList, season]);

  useEffect(() => {
    getExtras();
    getSeasonPrice();
    getSubPrices();
    return () => {
      initOrder();
      setStep(0);
    };
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [step]);

  useEffect(() => {
    const timeObject = DateTime.fromISO(end_date!)
      .diff(DateTime.fromISO(start_date!), ['days', 'hours'])
      .toObject();
    const { hours, days } = timeObject;

    let priceExtra = 0;
    let onewayPrice = 0;
    let summerPrice = 0;
    let discountPrice = 0;
    let vanPrice = dineroMultiply(order.van.price, timeObject.days!);

    if (hours && days) {
      if (hours <= 6 && hours > 0) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroDivide(order.van?.price!, 4),
          order.currency!
        );
      } else if (hours > 6 && hours <= 12) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroDivide(order.van?.price!, 2),
          order.currency!
        );
      } else if (hours > 12 && hours <= 18) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroSubtractAmount(
            order.van?.price!,
            dineroDivide(order.van?.price!, 4),
            order.currency!
          ),
          order.currency!
        );
      } else if (hours > 18 && hours <= 24) {
        vanPrice = dineroAdd(vanPrice, order.van?.price!, order.currency!);
      }
    }

    if (!vanPrice) vanPrice = order.van.price;

    if (end_location) {
      onewayPrice = filter(onewayList, {
        start_location: location,
        end_location,
      })[0].price;

      const oneway = order.extras.filter((i) => i.id === 10)[0];
      if (oneway) {
        oneway.price = onewayPrice;
        oneway.title = oneway.title.includes(end_location)
          ? oneway.title
          : `${oneway.title} ${end_location}`;
      }
    }
    order.extras.map((i) => {
      if (i.id === 6 || i.id === 7 || i.id === 16) i.price = 0;
      else if (i.id === subId) {
        const sublocation = order.van.host.sublocation_price.filter((i) => i.location === location);
        if (sublocation.length) {
          i.price = sublocation[0].price;
        } else if (subPriceList.length) {
          const sublocation = subPriceList.filter((sub) => sub.location === sub_location);
          if (sublocation.length) {
            i.price = sublocation[0].price;
          } else {
            const extras = order.extras.filter((i) => i.id !== subId);
            changeOrder('extras', extras);
          }
        }
      }
      // else if (i.id === 17) { // near van
      //   order.van.near_van.map((nv) =>
      //     nv.location === location && (i.price = nv.price)
      //   );
      // }
    });
    order.extras.forEach(
      (extra) => (priceExtra += extra.id !== 10 ? extra.price : 0)
    );

    let fullPrice = priceExtra + vanPrice;
    if (season && order.price) {
      const summerExtra = order.extras.filter((i) => i.id === 6);
      if (summerExtra.length) {
        let duration;
        if (fromISOtoFormat(end_date!, 'yyyy/LL/dd')
          >= fromISOtoFormat(season.end_date, 'yyyy/LL/dd')
        ) {
          duration = DateTime.fromISO(season.end_date!)
            .diff(DateTime.fromISO(start_date!), 'days');
        } else {
          duration = DateTime.fromISO(end_date!)
            .diff(DateTime.fromISO(start_date!), 'days')
            .toObject();
        }
        const vanPrice = dineroMultiply(order.van.price, Math.ceil(duration.days!));
        summerExtra[0].price = dineroGetPercentage(vanPrice, Math.abs(season.rate));
        summerPrice = dineroMultiply(summerExtra[0].price, Math.sign(season.rate));
        summerExtra[0].price = summerPrice;
      }
    }

    fullPrice = dineroGetSum(fullPrice, summerPrice);
    if (days && order.price) {
      const discountExtra = order.extras.find((i) => i.id === 7);
      if (discountExtra) {
        if (days === 7) {
          discountPrice = discountExtra!.price = -dineroGetPercentage(
            fullPrice,
            5
          );
        } else if (days > 7 && days <= 21) {
          discountPrice = discountExtra!.price = -dineroGetPercentage(
            fullPrice,
            10
          );
        } else if (days > 21 && days <= 30) {
          discountPrice = discountExtra!.price = -dineroGetPercentage(
            fullPrice,
            20
          );
        } else if (days > 30) {
          discountPrice = discountExtra!.price = -dineroGetPercentage(
            fullPrice,
            27
          );
        }
      }
    }
    fullPrice = dineroGetSum(fullPrice, discountPrice);
    const fullPriceWithOneway = dineroGetSum(fullPrice, onewayPrice);
    const cardFeeExtra = order.extras.filter((i) => i.id === 16);
    const cardFee = dineroGetPercentage(fullPriceWithOneway, 3);
    cardFeeExtra.length && (cardFeeExtra[0].price = cardFee);
    changeOrder('price', dineroGetSum(fullPriceWithOneway, cardFee));
  }, [
    order.extras,
    order.extras.length,
    order.van,
    end_date,
    start_date,
    season,
    subPriceList
  ]);

  const onDataChange = (name: string, value: any) => changeOrder(name, value);

  const handleClearOrder = () => {
    initOrder();
    navigate('/');
    scrollToTop();
  };

  const _renderCurrentStep = (): JSX.Element | undefined => {
    switch (step) {
      case 0:
        return (
          <Step1
            order={order}
            onChange={onDataChange}
            setStep={setStep}
            handleClearOrder={handleClearOrder}
          />
        );
      case 1:
        return (
          <Step2 order={order} onChange={onDataChange} setStep={setStep} />
        );
      case 2:
        return (
          <Step3
            order={order as OrderState & { client: IClientFetch }}
            onChange={onDataChange}
            setStep={setStep}
          />
        );
      default:
        return (
          <Step1
            order={order}
            onChange={onDataChange}
            setStep={setStep}
            handleClearOrder={handleClearOrder}
          />
        );
    }
  };

  return (
    <div className='order'>
      <div className='order__header'>
        <Logo />
        <Stepper activeStep={step} steps={steps.map((step) => step.label)} />
        <InfoStepper />
      </div>
      <div className={step !== 2 ? 'order__wrapper' : ''}>
        {_renderCurrentStep()}
      </div>
    </div>
  );
};

export default OrderPage;
