import { FC } from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
import { IVanTag } from 'src/models/IVan';
import Icons from 'src/common/icons';

interface IProps {
  location: string;
  tags: IVanTag[];
  style?: { [key: string]: string };
  className: string;
}

const AvailableLocations: FC<IProps> = ({
  location,
  tags,
  style,
  className,
}) => {
  const isMobile = useIsMobile();
  const deliveryLocations = tags.filter(
    (t) => t.category === 'location' && t.name !== location
  );

  return (
    <div className={className} style={style}>
      {isMobile ? (
        <>
          <Icons.Label classes='gap-icon' />
          <span>
            <b>Available in and around: </b>
            {`${location}, ${deliveryLocations!.map((t) => t.name)}.`}
          </span>
        </>
      ) : (
        <>
          <div>
            <Icons.Label />
            <b>Available in and around: </b>
          </div>
          {`${location}, ${deliveryLocations!.map((t) => t.name)}.`}
        </>
      )}
    </div>
  );
};

export default AvailableLocations;
