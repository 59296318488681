import { FC, useState, useEffect } from 'react';
import VanCard from '../../../item/index';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import Icons from 'src/common/icons';

interface IProps {
  loading: boolean;
  show: number;
  setLoading: (loading: boolean) => void;
  setShow: (show: number) => void;
}

const InfiniteScrollRegionList: FC<IProps> = ({
  loading,
  show,
  setLoading,
  setShow,
}) => {
  const { vanList, errors, filters, isFilterValidate } = useTypedSelector(
    (state) => state.van
  );

  const dataWithoutFIlter = Object.keys(vanList).filter(
    (location) => location !== filters.location
  );

  const [list, setList] = useState(dataWithoutFIlter.slice(0, 1));

  useEffect(() => {
    if (!loading) return;
    if (show >= dataWithoutFIlter.length) return setLoading(false);
    setTimeout(() => {
      setList(list.concat(dataWithoutFIlter.slice(show, show + 1)));
      setShow(show + 1);
      setLoading(false);
    }, 1000);
  }, [loading]);

  return (
    <>
      {list.map((location) => (
        <>
          {/* <div className="vans__locations-nearby">
                        <div>We have vans available in: </div>
                        <div className="vans__locations-nearby__location-from">
                            <span className="vans__locations-nearby__location-from__img">
                                <Icons.Label />
                            </span>
                            <b>{location}</b>
                        </div>
                    </div> */}
          {vanList[location].map((van) => (
            <VanCard
              van={van}
              region={location}
              key={van.id}
              errors={errors}
              isFilterValidate={isFilterValidate}
            />
          ))}
        </>
      ))}
    </>
  );
};
export default InfiniteScrollRegionList;
